

































































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator';
import Procedures from '../../../modules/Procedures';
import Ripple from 'vue-ripple-directive'
import {
  BFormTextarea, BInputGroupAppend, BSidebar, BCard, BTable, BBadge, BContainer, BRow, BCol, BForm, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import { ProcActivityNotesTypes } from '../../../types/procedureTypes';

@Component({
  components: {
    BSidebar,
    BTable,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BForm,
    BPagination,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BContainer,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BFormTextarea,
  },
  directives: {
    Ripple,
  }
})
export default class ActivityAndNotesCC extends Vue {

  // DATA
  @Ref('simpleRules') readonly refs_simpleRules!: any;
  procedures = new Procedures();
  apiData:ApiMethodResponse<ProcActivityNotesTypes>;
  items:Array<ProcActivityNotesTypes> = [];
  selectedRow:ProcActivityNotesTypes = {
    id: null,
    date: null,
    time: null,
    actionBy: null,
    change: null,
  };
  mode = '';
  result:ApiMethodResponse<ProcActivityNotesTypes>;
  sidebar:boolean = false;
  perPage = 5;
  pageOptions = [3, 5, 10];
  totalRows = 1;
  currentPage = 1;
  sortBy = '';
  sortDesc = false;
  sortDirection = 'asc';
  filter = null;
  filterOn = [];
  selector = null;
  fields = [
    { key: 'date', label: 'Date', sortable: true },
    { key: 'time', label: 'Time', sortable: true },
    { key: 'actionBy', label: 'Action By'},
    { key: 'change', label: 'Change / Note', sortable: true },
    { key: 'action', label: 'Actions'},
  ];

  // METHODS
  onRowSelected(rowItem, mode) {
    this.mode = mode;
    if(mode == 'add') {
      this.selectedRow = {
        id: null,
        date: null,
        time: null,
        actionBy: null,
        change: null,
      };
    } else {
      this.selectedRow = { ...rowItem };
    }
    this.sidebar = true;
  }
  deleteRow(id) {
    alert("Delete " + id);
    this.sidebar = false;
    this.result = this.procedures.deleteActivityAndNotesCC(id);
  }
  validationForm() {
    this.refs_simpleRules.validate().then(success => {
      if (success) {
        // eslint-disable-next-line
        alert('form submitted!');
        if(this.mode == 'add') {
          this.result = this.procedures.addActivityAndNotesCC(this.selectedRow);
        } else if (this.mode == 'edit') {
          this.result = this.procedures.updateActivityAndNotesCC('', this.selectedRow);
        }
      }
    })
  }
  onFiltered(filteredItems: any) {
    this.totalRows = filteredItems.length
    this.currentPage = 1
  }

  // CREATED
  async created() {
    this.apiData = await this.procedures.getActivityNotes('id');
    this.items = [ ...this.apiData.data.list || [] ];
  }

  // MOUNTED
  mounted() {
    this.totalRows = this.items.length
  }
}
